import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@material-ui/core";
import Meme from "../../components/Meme";
import MemeComments from "../../components/MemeComments";
import { getLimitedMemes } from "../../services/memeService";
import NewMeme from "../../components/NewMeme";
import { useRecoilState } from "recoil";
import { memesState } from "../../state";

const DashboardLimitedMemes = () => {
  const [memes, setMemes] = useRecoilState(memesState);
  const [lastevaluatedvalue, setLastEvaluatedValue] = useState('');

  useEffect(() => {
    const fetchMemes = async () => {
      try {
        const { data } = await getLimitedMemes();
        setMemes(data.Items);
        ("LastEvaluatedKey" in data) ? setLastEvaluatedValue(data.LastEvaluatedKey.Id) : setLastEvaluatedValue('');
      } catch (ex) {}
    };
    fetchMemes();
  }, [setMemes]);

  const addMemeInState = (newMeme) => setMemes(state => [...state, newMeme])

  const fetchMoreMemes = async () => {

    try {
      await getLimitedMemes(lastevaluatedvalue).then(result => {
        
        const { data } = result;
        data.Items.length && data.Items.map((i) => (
          addMemeInState(i)
        ))
        if("LastEvaluatedKey" in data){
          setLastEvaluatedValue(data.LastEvaluatedKey.Id);
        }
        else{
          setLastEvaluatedValue('');
        }
      })      
    } catch (ex) {}

  }

  const buttonStyle = {
    width: "100%",
    padding: "10px",
    backgroundColor: "#3f51b5",
    color: "white",
    border: "0px",
    fontSize: "15px",
    cursor: "pointer",
    marginBottom: "30px",
    marginTop: "20px"
  }

  return (
    
    <Container maxWidth="sm">
      <NewMeme />
      {memes.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : null}

      {memes.map((meme) => (
        <>
          <div>
            <Meme key={meme.Id} meme={meme} />
          </div>
          <div>
            <MemeComments key={meme.Id} meme={meme} />
          </div>
        </>
      ))}
      { lastevaluatedvalue.length && <button style={buttonStyle} onClick={fetchMoreMemes}>Fetch More  Memes</button>  }
    </Container>
  );
};

export default DashboardLimitedMemes;
