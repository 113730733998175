import { Box } from "@material-ui/core";

// import { useEffect, useState } from "react";
// import { useSnackbar } from "notistack";
// import { createComment, getAllMemeComments } from "../services/memeService";

const MemeCommentsFormat = ({ comment }) => {

  const singleCommentStyle = {
    backgroundColor: "#d8d8d8",
    margin: 0,
    marginBottom: "4px",
    padding: "10px 15px",
    borderRadius: "5px"
  }
  
  const imgStyle = {
    width: "50px",
    height: "50px",
    display: "inline-flex",
    backgroundColor: "black",
    verticalAlign: "middle",
    borderRadius: "50px",
    overflow: "hidden",
    marginRight: "10px"
  }

  return (
    <>
      {/* <Box m={1}>
          <TextareaAutosize key={meme.Id} meme-id={meme.Id} style={textareaStyle}  onBlur={handleAddComment}></TextareaAutosize>
      </Box> */}
      <Box m={1}>
          {
            <div style={singleCommentStyle}>
              <p>
                <span style={imgStyle}>
                  <img src={comment.UserProfile.Item.ImageUrl} alt="profile" width="100%"/>
                </span>
                <strong>{comment.UserProfile.Item.given_name}</strong>
              </p>
              <p  key={comment.id} comment-id={comment.id}>{comment.Comment}</p>
            </div>
              
          }
      </Box>

    </>
  );
};

export default MemeCommentsFormat;
