import {
  Box,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import { Cancel, Delete, Edit, MoreVert } from "@material-ui/icons";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { deleteMeme } from "../services/memeService";
import { useRecoilState, useRecoilValue } from "recoil";
import { memesState, userState } from "../state";
import { removeItemAtIndex } from "../utils";
import EditMeme from "./EditMeme";

const Meme = ({ meme }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [memes, setMemes] = useRecoilState(memesState);
  const index = memes.findIndex((listItem) => listItem === meme);
  const user = useRecoilValue(userState);
  const confirm = useConfirm();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    try {
      confirm({ description: "Are you sure you want to delete this meme?" })
        .then(() => deleteMeme(meme.Id))
        .then(() => setMemes(removeItemAtIndex(memes, index)))
        .then(() =>
          snackbar("Meme deleted successfully!", { variant: "success" })
        )
        .catch((ex) => {});
    } catch (ex) {
      snackbar("Error: " + ex?.response?.data?.message, { variant: "error" });
    }
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEdit(true);
    setAnchorEl(null);
  };

  // const handleLike = () => {

  //   try {
  //       deleteMeme(meme.Id)
  //       .then(() => setMemes(removeItemAtIndex(memes, index)))
  //       .then(() =>
  //         snackbar("Meme deleted successfully!", { variant: "success" })
  //       )
  //       .catch((ex) => {});
  //   } catch (ex) {
  //     snackbar("Error: " + ex?.response?.data?.message, { variant: "error" });
  //   }
  //   setAnchorEl(null);
  // };

  if (edit) {
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => setEdit(false)}>
            <Cancel />
          </IconButton>
        </Box>
        <EditMeme setEdit={setEdit} meme={meme} />{" "}
      </>
    );
  }

  return (
    <Card sx={{ marginTop: 2 }}>
      <CardHeader
        title={meme.Content}
        action={
          user.sub === meme.UserId && (
            <>
              <IconButton onClick={handleMenu}>
                <MoreVert />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={handleEdit}>
                  <Edit />
                  Edit
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  <Delete />
                  Delete
                </MenuItem>
              </Menu>
            </>
          )
        }
      />
      <CardMedia
        style={{ height: 200 }}
        image={meme.ImageUrl}
        title={meme.Content}
      />
      <CardActions>
        <IconButton>
          <ThumbUpIcon  />
        </IconButton>
        <IconButton>
          <ThumbDownIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default Meme;
