import Axios from "axios";

const url =
  "https://2nx1hb1edj.execute-api.us-east-1.amazonaws.com/Stage/NaqashSamMemes/";

const comment_url =
  "https://w9ssbtt0c0.execute-api.us-east-1.amazonaws.com/Prod/NaqashSamMemeComments/";

const like_dislike_url =
  "https://3p8ldgbqt9.execute-api.us-east-1.amazonaws.com/Prod/NaqashMemeLikesDislikes/";

const limited_memes_url =
  "https://ttbpg04zp9.execute-api.us-east-1.amazonaws.com/Prod/NaqashSamGETsetOfMemes";

export const createMeme = (Content, ImageUrl) => {
  return Axios.post(url, { Content, ImageUrl });
};

export const getAllMemes = () => {
  return Axios.get(url);
};

export const deleteMeme = (id) => {
  return Axios.delete(url + id);
};

export const updateMeme = (id, Content, ImageUrl) => {
  return Axios.put(url + id, { Content, ImageUrl });
};

export const getAllMemeComments = (id) => {
  return Axios.get(comment_url + id);
};

export const createComment = (memeId, Comment) => {
  let url = comment_url + memeId
  return Axios.post(url, {Comment});
};

export const likeMeme = (meme_id) => {
  const d = { MemeId: meme_id };
  return Axios.post(like_dislike_url + 'Like', d);
};

export const dislikeMeme = (meme_id) => {
  const d = { MemeId: meme_id };
  return Axios.post(like_dislike_url + 'Dislike', d);
};

export const getLimitedMemes = ( lev = '' ) => {
  let url = limited_memes_url + "?Limit=5";
  if(lev.length){
    url = url + '&LastEvaluatedKey=' + lev
  }
  return Axios.get(url);
};

export const getLimitedComments = ( meme_id, comment_id = '', lastupdatedts = '' ) => {

  let url = comment_url + meme_id + "?Limit=2&MemeId="+meme_id ;
  if(comment_id.length){
    url = url + '&Id=' + comment_id
  }
  
  if(lastupdatedts !== ''){
    url = url + '&lastUpdatedTs=' + lastupdatedts
  }
  return Axios.get(url);
};