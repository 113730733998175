import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, TextareaAutosize } from "@material-ui/core";
import Meme from "../../components/Meme";
import MemeCommentsFormat from "../../components/MemeCommentsFormat";
import { getAllMemes, getLimitedComments, createComment } from "../../services/memeService";
import NewMeme from "../../components/NewMeme";
import { useRecoilState } from "recoil";
import { memesState } from "../../state";
// import { LegendToggleTwoTone } from "@material-ui/icons";
import { useSnackbar } from "notistack";

const Dashboard = () => {
  const [memes, setMemes] = useRecoilState(memesState);
  const [comments, setComments] = useState({});
  const { enqueueSnackbar: snackbar } = useSnackbar();

  useEffect(() => {
    const fetchMemes = async () => {
      try {
        const { data } = await getAllMemes();
        setMemes(data);
      } catch (ex) {}
    };
    fetchMemes();
  }, [setMemes]);

  const fetchComments = async (meme_id) => {
    let comment_id = '';
    let lastupdatedts = '';

    if(meme_id in comments && comments[meme_id].length){
      let comms = comments[meme_id];
      let comment = comms[comms.length - 1];
      comment_id = comment.Id;
      lastupdatedts = comment.lastUpdatedTs;
    }

    try {
      await getLimitedComments(meme_id, comment_id, lastupdatedts).then(result => {

        const { data } = result;

        let all_local_comments = comments;
        let meme_local_comments = (meme_id in comments) ? comments[meme_id] : [];

        data.Items.map((comment) => (
          meme_local_comments.push(comment)
        ));

        // console.log(aq);
        
        all_local_comments[meme_id] = meme_local_comments;

        setComments(all_local_comments);

        setComments({...comments});
        
      })      
    } catch (ex) {}

  }

  const handleAddComment = (e) => {

    if (e.key === 'Enter') {
      let comment = e.target.value;
      let meme_id = e.target.getAttribute('meme-id');
  
      try {
        const { newcomment } =  createComment(meme_id, comment);
        console.log(newcomment);
  
        e.target.value = '';

        snackbar("Comment posted successfully!", { variant: "success" });
      } catch (ex) {
        snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
      }
    }    

  }

  const textareaStyle = {
    height: "100px",
    width: "98%",
    resize: "none",
    padding: "5px"
  }

  const buttonStyle = {
    width: "100%",
    padding: "10px",
    backgroundColor: "#3f51b5",
    color: "white",
    border: "0px",
    fontSize: "15px",
    cursor: "pointer",
    marginBottom: "30px",
    marginTop: "20px"
  }
  
  return (
    <Container maxWidth="sm">
      <NewMeme />
      {memes.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : null}

      {memes.map((meme) => (
        <>
          <div>
            <Meme key={meme.Id} meme={meme} />
          </div>

          <Box m={1}>
              <TextareaAutosize key={meme.Id} meme-id={meme.Id} style={textareaStyle}  onKeyPress={handleAddComment} placeholder="Write Comment... Press enter to submit"></TextareaAutosize>
          </Box>
          
          {comments[meme.Id] && comments[meme.Id].map(comment => 
            <MemeCommentsFormat key={comment.Id} comment={comment} />
          )}

          <button style={buttonStyle} onClick={(e)=> {fetchComments(meme.Id)}} > { (comments[meme.Id]) ? "Fetch More" : "Get Comments" }</button>
        </>
      ))}
    </Container>
  );
};

export default Dashboard;
