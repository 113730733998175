import { Box, TextareaAutosize } from "@material-ui/core";

import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { createComment, getAllMemeComments } from "../services/memeService";

const MemeComments = ({ meme }) => {
  const [memecomments, setMemeComments] = useState(null);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  // const [commentloadmorebuttons, setCommentLoadMoreButtons] = useState([]);
  // const [currentselectedmeme, setCommentLoadMoreButtons] = useState('');
  // const [newcomment, setNewcomment] = useState(null);
  

  useEffect(() => {
    
    const fetchCommentsData = async () => {
      try {
        const { data } = await getAllMemeComments(meme.Id);
        setMemeComments(data);
      } catch (ex) {
        snackbar("Unable to fetch data.", { variant: "error" });
      } finally {
        
      }
    };
    fetchCommentsData();

  }, [meme.Id, setMemeComments, snackbar]);

  const handleAddComment = (e) => {
    let comment = e.target.value;
    let meme_id = e.target.getAttribute('meme-id');

    try {
      const { newcomment } =  createComment(meme_id, comment);
      console.log(newcomment);
      // const { data: newMeme } = await createMeme(values.content, imageUrl);
      // setMemes(addItemAtStart(memes, newMeme));
      snackbar("Comment posted successfully!", { variant: "success" });
    } catch (ex) {
      snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
    }

  }

  const singleCommentStyle = {
    backgroundColor: "#d8d8d8",
    margin: 0,
    marginBottom: "4px",
    padding: "10px 15px",
    borderRadius: "5px"
  }

  const textareaStyle = {
    height: "100px",
    width: "99%",
    resize: "none"
  }

  const imgStyle = {
    width: "50px",
    height: "50px",
    display: "inline-flex",
    backgroundColor: "black",
    verticalAlign: "middle",
    borderRadius: "50px",
    overflow: "hidden",
    marginRight: "10px"
  }

  return (
    <>
      <Box m={1}>
          <TextareaAutosize key={meme.Id} meme-id={meme.Id} style={textareaStyle}  onBlur={handleAddComment}></TextareaAutosize>
      </Box>
      <Box m={1}>
          {
            memecomments && memecomments.Items.map(mc => 
              <div style={singleCommentStyle}>
                <p>
                  <span style={imgStyle}>
                    <img src={mc.UserProfile.Item.ImageUrl} alt="profile" width="100%"/>
                  </span>
                  <strong>{mc.UserProfile.Item.given_name}</strong>
                </p>
                <p  key={mc.id} comment-id={mc.id}>{mc.Comment}</p>
              </div>
              
            )
          }
      </Box>

    </>
  );
};

export default MemeComments;
