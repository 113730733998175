import { Navigate, Outlet } from "react-router-dom";
import NewMeme from "./components/NewMeme";
import MainLayout from "./layouts/MainLayout";
import Dashboard from "./views/app/Dashboard";
import DashboardLimitedMemes from "./views/app/DashboardLimitedMemes";
import Profile from "./views/app/Profile";
import ConfirmSignup from "./views/auth/ConfirmSignup";
import ForgotPassword from "./views/auth/ForgotPassword";
import ResendConfirmation from "./views/auth/ResendConfirmation";
import ResetPassword from "./views/auth/ResetPassword";
import SignIn from "./views/auth/SignIn";
import SignUp from "./views/auth/SignUp";

const routes = (user) => [
  {
    path: "/auth",
    element: user ? <Navigate to="/" /> : <Outlet />,
    children: [
      { path: "signin", element: <SignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "confirm-signup", element: <ConfirmSignup /> },
      { path: "resend-confirmation", element: <ResendConfirmation /> },
    ],
  },
  {
    path: "/",
    element: user ? <MainLayout /> : <Navigate to="/auth/signin" />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "add", element: <NewMeme /> },
      { path: "profile", element: <Profile /> },
      { path: "newdashboard", element: <DashboardLimitedMemes /> }
    ],
  },
];

export default routes;
